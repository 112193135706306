import { Route, Routes } from "react-router-dom";
import Layout from "./pages/layout";
import UserDataTable from "./pages/home/user-data-table";
import ManageDataTable from "./pages/home/manage-table";
import ApproveUsers from "./pages/home/approve-users";
function AppRoutes() {
  return (
    <Routes>
      <Route
        index
        element={
          <Layout>
            <ManageDataTable />
          </Layout>
        }
      />
      <Route
        path="/manage-accounts"
        element={
          <Layout>
            <ManageDataTable />
          </Layout>
        }
      />
      <Route
        path="/user-info"
        element={
          <Layout>
            <UserDataTable />
          </Layout>
        }
      />
      <Route
        path="/approve-users"
        element={
          <Layout>
            <ApproveUsers />
          </Layout>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
