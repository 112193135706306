import React,{useEffect,useState} from 'react';
import { toast } from 'sonner';
import Loader from '../component/loader'
import addUserIcon from '../../../assets/images/add.png'
import AddUserModal from './component/AddUserModal';

const people = [
  { name: 'Lindsay Walton', title: 'Alter Password', addAction: 'Add', deletAction: 'Delete' },
  { name: 'Lindsay Walton', title: 'Alter Password', addAction: 'Add', deletAction: 'Delete' },
  { name: 'Lindsay Walton', title: 'Alter Password', addAction: 'Add', deletAction: 'Delete' },
]
const headings = [
  { title: 'Accounts', id: '1' },
  { title: 'Email', id: '2' },
  { title: 'Password', id: '3' },
  { title: 'Actions', id: '4' },
]

const ManageDataTable = () => {
  const [users,setUsers]=useState();
  const [loader,setLoader]=useState(true);
  const [alterPasswordIndex, setAlterPasswordIndex] = useState(-1);
  const [password,setPassword]= useState();
  const [confirmPassword,setConfirmPassword]=useState();
  const [toggleAddUser,setToggleAddUser]= useState(false);
  const [fetchUsers,setFetchUsers]=useState();
  const handleAlterPassword = (index) => {
    setAlterPasswordIndex(index);
  };
  const [currentPage,setCurrentPage]=useState(1);
  const [usersPerPage] =useState(10);
  const indexOfLastUser =usersPerPage*currentPage;
  const indexOfFirstUser = indexOfLastUser-usersPerPage;
  const currentUsers = users?.slice(indexOfFirstUser,indexOfLastUser);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users?.length / usersPerPage); i++) {
      pageNumbers.push(i);
  }
  const handlePageChange=(pageNumber)=>{
    setCurrentPage(pageNumber)
}
  async function deleteUser(userId){
    console.log("first",userId)
    const updatedUsers = users.filter((user)=>{
      return userId !== user._id
    })
    setUsers(updatedUsers);
    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/delete-user/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          console.log('fetch delete user response', res);
          if (res.ok) {
            const response = await res.json();
            
            toast.info("User Deleted Successfully!");
            
          }
        })
        .catch((err)=>{
          console.log("Error deleting users",err)
        })
    } catch (error) {
      console.log("Error deleting users",error)
    }
  }
  useEffect(()=>{
    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/fetch-users`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          console.log('fetch users response', res);
          if (res.ok) {
            const response = await res.json();
            console.log("first",response)
            setUsers(response.users)
            setLoader(false)
           
          }
        })
        .catch((err)=>{
          console.log("Error fetching users",err)
        })
    } catch (error) {
      console.log("Error fetching users",error)
    }
    },[fetchUsers])
    async function savePassword(email){
      if (password !== confirmPassword) {
        toast.error("Passwords Don't Match!!");
      } else if(password && confirmPassword && confirmPassword === password){
        const bodyData = {
          email: email,
          password: { password: password, confirmNewPassword: confirmPassword }
        };
        fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        })
          .then(async (res) => {
            console.log('update-password', res);
            if (res.ok) {
              toast.message('Password updated!');
              setAlterPasswordIndex(-1);
            } else {
              toast.error('Error Updating Password');
            }
          })
          .catch((err) => {
            console.log('Error updating password', err);
          });
      }
      else if(!password || !confirmPassword){
        toast.info('Password field cannot be empty!')
      }
    }
    async function addUser(){
      const requestBOdy = {
        email:newUserData.email,
        password:newUserData.password,
        fullname:newUserData.name
      };
  
      if(newUserData.password===newUserData.confirmPassword){
      // form is submitted
      setFetchUsers('No');
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/auth/register`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
              // Add any other headers as needed
            },
            body: JSON.stringify(requestBOdy)
          }
        );
  
        if (response.ok) {
          const data = await response.json();
          console.log({ data });
  
          if (data?.status == 200) {
            toast.info('User Added Successfully!');
            setLoader(true);
            setFetchUsers('yes'); //changing dependency array of use effect so that updated data is shown 
            setToggleAddUser(false)
            setNewUserData({
              name: '',
              email: '',
              password: '',
              confirmPassword: '',
            });
            
          } else {
            //an error occured on backend
            throw new Error(data.message);
          }
        } else {
          console.log('errr');
  
          throw new Error('Unable to connect to backend');
        }
      } catch (error) {
        toast.error(`${error?.message}`);
        console.log(error.message);
        console.log(error.msg);
  
        // Handle network errors or exceptions
        console.error('Error:', error);
      }
    }
    else{
      toast.info("Passwords Doesn't Matched!")
    }

    }
    const [newUserData, setNewUserData] = useState({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
  
    const handleNewUserChange = (e) => {
      const { name, value } = e.target;
      setNewUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    
  return (
    <div className="px-10 h-full">
      <div className="sm:flex sm:items-center lg:mt-20">
        <div className="flex  w-full">
          <h1 className="flex justify-center lg:justify-start text-4xl font-semibold leading-6 text-gray-900 w-full tex-left">Manage accounts</h1>
             <button className="px-3 py-2 w-[135px] flex items-center gap-2 lg:justify-end bg-teal-500 text-white rounded-sm" onClick={()=>{
                setToggleAddUser(true);
            }}>
              <img src={addUserIcon} className="w-5 h-5 "></img>Add User</button>
          
      

        </div>
      </div>
      <AddUserModal
        isOpen={toggleAddUser}
        onClose={() => setToggleAddUser(false)}
        onSubmit={addUser}
        onChange={handleNewUserChange}
        userData={newUserData}
      />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className='border-gray-200 border rounded-t-sm'>
                  {headings.map((heading) => (
                    <th key={heading.id} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      <a href="#" className="group inline-flex">
                        {heading.title}
                      </a>
                    </th>
                  ))}
                  
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {currentUsers?.map((person,index) => (
                  <tr key={person._id} className='border border-gray-200'>
                    <td className="whitespace-nowrap text-center py-4 px-3 text-sm font-medium text-gray-900">
                      {person.fullname}
                    </td>
                    <td className="whitespace-nowrap text-center py-4 px-3 text-sm font-medium text-gray-900">
                      {person.email}
                    </td>
                    <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                    {alterPasswordIndex === index ? (
                        <div className="flex items-center">
                          <input
                            type="password"
                            placeholder="New Password"
                            className="border border-gray-300 px-3 py-2 rounded-md mr-2"
                            onChange={(e)=>{
                              setPassword(e.target.value)
                            }}
                          />
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            className="border border-gray-300 px-3 py-2 rounded-md mr-2"
                            onChange={(e)=>{
                              setConfirmPassword(e.target.value)
                            }}
                          />
                          <button className="bg-teal-500 text-white px-3 py-2 rounded-md" onClick={()=>{
                            savePassword(person?.email)
                          }}>
                            Save
                          </button>
                        </div>
                      ) : (
                        <button
                          className="px-3 py-2 bg-[#F59D0F] text-white rounded-sm"
                          onClick={() => handleAlterPassword(index)}
                        >
                          Alter Password
                        </button>
                      )}
                      </td>
                    <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500"><button className='px-3 py-2 bg-rose-500 text-white rounded-sm' onClick={()=>{
                      deleteUser(person._id);
                    }}>Delete</button></td>
                  </tr>
                ))}
              </tbody>
             
            </table>
            
          </div>
         
        </div>
       
      </div>
      {loader && (
  <div className='flex justify-center items-center h-full'>
    <Loader/>
  </div>
          )}<div className="flex justify-center items-end mt-10">
          {pageNumbers.map(number => (
              <button
                  key={number}
                  onClick={() => handlePageChange(number)}
                  className={`mx-1 px-4 py-2 border rounded-lg text-sm transition-colors duration-300 ${currentPage === number ? 'bg-teal-500 text-white border-teal-500' : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200'}`}
              >
                  {number}
              </button>
          ))}
          </div>
    </div>
  )
}
export default ManageDataTable