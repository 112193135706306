import React from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    Cog6ToothIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    UserPlusIcon
} from '@heroicons/react/24/outline'
import ClueLogo from "../../../assets/images/clue-logo.png"


const navigation = [
    { name: 'Manage Accounts', href: '/manage-accounts', icon: HomeIcon,  },
    { name: 'Users', href: '/user-info', icon: UsersIcon, },
    { name: 'Approve Users', href: '/approve-users', icon: UserPlusIcon, },

]
const userNavigation = [
    { name: 'Sign out', href: '#' },
]

const SideBar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState(navigation[0].name); 
    const handleTabClick = (name) => {
        setCurrentTab(name);
        console.log("CLIOCKED")
        console.log("first",name)
    };
    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                        <div className="flex justify-center items-center">
                                            <img
                                                className="max-w-[200px] max-h-[62px] my-[20px]"
                                                src={ClueLogo}
                                                alt={'Clue Logo'}
                                            />
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                                <li>
                                                    <ul role="list" className="-mx-2 space-y-1">
                                                    {navigation.map((item) => (
                                                         <li key={item.name}>
                                                         <a
                                                             href={item.href}
                                                             className={`${item.current
                                                                 ? 'bg-teal-500 text-white'
                                                                 : ' hover:text-white hover:bg-teal-500'}
                                                             group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold`}
                                                         >
                                                             <item.icon
                                                                 className={`${item.current ? 'text-white' : 'group-hover:text-white'} h-6 w-6 shrink-0`}
                                                                 aria-hidden="true"
                                                             />
                                                             {item.name}
                                                         </a>
                                                     </li>
                                                         ))}
                                                    </ul>
                                                </li>
                                                <li className="mt-auto">
                                                    <a
                                                        href="#"
                                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-teal-500 hover:text-white"
                                                    >
                                                        <Cog6ToothIcon
                                                            className="h-6 w-6 group-hover:text-white"
                                                            aria-hidden="true"
                                                        />
                                                        Logout
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white border-r border-gray-100 shadow-xl px-6 pb-4">
                        <div className="flex justify-center items-center">
                            <img
                                className="max-w-[200px] max-h-[62px] my-[20px]"
                                src={ClueLogo}
                                alt={'Clue Logo'}
                            />
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        {navigation.map((item) => (
                                            <li key={item.name}>
                                                <a
                                                    href={item.href}
                                                    className={`${item.current
                                                        ? 'bg-teal-500 text-white'
                                                        : ' hover:text-white hover:bg-teal-500'}
                                                    group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold`}
                                                >
                                                    <item.icon
                                                        className={`${item.current ? 'text-white' : 'group-hover:text-white'} h-6 w-6 shrink-0`}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="mt-auto">
                                    <a
                                        href="#"
                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-teal-500 hover:text-white"
                                    >
                                        <Cog6ToothIcon
                                            className="h-6 w-6 group-hover:text-white"
                                            aria-hidden="true"
                                        />
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className='lg:pl-72'>
                    <div className="items-center h-full shrink-0 gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
                        <div className='flex justify-start items-center pt-10'>
                        <button type="button" className="sticky top-4 right-0 -m-2.5 p-2.5 text-gray-900 lg:hidden" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        </div>
                    </div>
                        {/* <div className=""><UserDataTable /></div> */}
                </div>
            </div>
        </>
    )
}
export default SideBar